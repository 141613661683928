import axios from 'axios';
import { useState } from 'react';
import '../../styles/home/Contacts.scss';
import { useTranslation } from 'react-i18next';

function Contact({ changeShowSuccessModal }) {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");

    const handleSubmit = (event) => {
        event.preventDefault();

        axios.post(`https://api.unisoft.uz/send-application/`,
            {
                'name': name,
                'phone': phone,
                'email': email,
                'message': message,
            },
        )
            .then((res) => {
                console.log(res?.data);
                setName("");
                setEmail("");
                setPhone("");
                setMessage("");
                changeShowSuccessModal();
            })
            .catch((err) => {
                console.log(err);
            })
    };

    // i18next

    const { t } = useTranslation();

    return (
        <div className="Contacts parent" id='contacts'>
            <div className="wrapper gap-3">
                <p className="sub-title">{t("submit_btn")}</p>
                <form className="forms gap-2-5" onSubmit={handleSubmit}>
                    <input type="text" required className="input text" name='Name' placeholder={`${t("name")}`} value={name} onChange={(e) => setName(e.target.value)} />
                    <input type="email" required className="input text" name='Email' placeholder={`${t("email")} `} value={email} onChange={(e) => setEmail(e.target.value)} />
                    <input type="phone" required className="input text" name='Phone' placeholder={`${t("phone")} `} value={phone} onChange={(e) => setPhone(e.target.value)} />
                    <input type="text" className="input text" name='Message' placeholder={`${t("message")} `} value={message} onChange={(e) => setMessage(e.target.value)} />
                    <div className="btns mtop-1">
                        <button type="submit" className='send_btn scale-05 text'>{t("send_btn")}</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Contact

